import axios from '@/common/axios'
import qs from 'qs'

export function addDeliveryNote (data) {
  return axios({
    method: 'post',
    url: '/sale/note/add',
    data: qs.stringify(data)
  })
}

export function deleteDeliveryNote (id) {
  return axios({
    method: 'delete',
    url: '/sale/note/delete/' + id
  })
}

export function updateDeliveryNote (data) {
  return axios({
    method: 'put',
    url: '/sale/note/update',
    data: qs.stringify(data)
  })
}

export function selectDeliveryNoteInfo (id) {
  return axios({
    method: 'get',
    url: '/sale/note/info/' + id
  })
}

export function selectDeliveryNoteList (query) {
  return axios({
    method: 'get',
    url: '/sale/note/list',
    params: query
  })
}
