<template>
  <div id="deliveryNote">
    <el-dialog
      :title="deliveryNoteFormTitle"
      width="1200px"
      :visible.sync="deliveryNoteDialogVisible"
      :close-on-click-modal="false"
      @close="deliveryNoteDialogClose"
    >
      <el-form
        ref="deliveryNoteFormRef"
        :model="deliveryNoteForm"
        :rules="deliveryNoteFormRules"
        label-position="right"
        label-width="150px"
      >
        <el-row>
          <el-col :span="8">
            <el-form-item label="公司" prop="customerId">
              <el-select
                v-model="deliveryNoteForm.customerId"
                placeholder="请选择公司"
                clearable
                @change="customerChange"
              >
                <el-option
                  v-for="item in customerList"
                  :key="item.id"
                  :label="item.companyName"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="电话" prop="phone">
              <el-input v-model="deliveryNoteForm.phone" readonly />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="传真" prop="fax">
              <el-input v-model="deliveryNoteForm.fax" readonly />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="地址" prop="companyAddress">
              <el-input v-model="deliveryNoteForm.companyAddress" readonly />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="网址" prop="url">
              <el-input v-model="deliveryNoteForm.url" readonly />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="邮箱" prop="mailbox">
              <el-input v-model="deliveryNoteForm.mailbox" readonly />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="收货单位" prop="receivingUnit">
              <el-input v-model="deliveryNoteForm.receivingUnit" readonly />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="收货地址" prop="receivingAddress">
              <el-input v-model="deliveryNoteForm.receivingAddress" readonly />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="送货日期" prop="deliveryDate">
              <el-date-picker v-model="deliveryNoteForm.deliveryDate" placeholder="请选择送货日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item>
          <vxe-toolbar v-if="deliveryNoteFormTitle !== '产品送货单详情'">
            <template #buttons>
              <vxe-button @click="insertRowEvent()">
                新增
              </vxe-button>
            </template>
          </vxe-toolbar>
          <vxe-table
            ref="xTable"
            border
            resizable
            show-overflow
            keep-source
            :data="deliveryNoteDetailList"
            :edit-config="{trigger: 'click', mode: 'cell'}"
          >
            <vxe-table-column
              field="itemNo"
              title="货号"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="productId"
              title="品名"
              :edit-render="{
                name: '$select',
                props: {clearable: true},
                options: productList,
                optionProps: {
                  label: 'productName',
                  value: 'id'
                },
                events: {
                  change: productChange
                }
              }"
            />
            <vxe-table-column
              field="spec"
              title="规格"
              :edit-render="{name: '$input', props: {readonly: true}}"
            />
            <vxe-table-column
              field="unit"
              title="单位"
              :edit-render="{name: '$input', props: {readonly: true}}"
            />
            <vxe-table-column
              field="quantity"
              title="数量"
              :edit-render="{name: '$input', props: {type: 'number', clearable: true}}"
            />
            <vxe-table-column
              field="remarks"
              title="备注"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column v-if="deliveryNoteFormTitle !== '产品送货单详情'" title="操作" width="100">
              <template #default="{ row }">
                <template>
                  <vxe-button @click="removeRowEvent(row)">
                    删除
                  </vxe-button>
                </template>
              </template>
            </vxe-table-column>
          </vxe-table>
        </el-form-item>
        <el-row>
          <el-col :span="12">
            <el-form-item label="收货单位（盖章）" prop="">
              <el-input clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="送货单位（盖章）" prop="">
              <el-input clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="经手人签字" prop="receivingOperator">
              <el-input v-model="deliveryNoteForm.receivingOperator" placeholder="请输入经手人签字" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="经手人签字" prop="deliveryOperator">
              <el-input v-model="deliveryNoteForm.deliveryOperator" placeholder="请输入经手人签字" clearable />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="deliveryNoteDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="deliveryNoteFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="公司名称">
        <el-input v-model="searchForm.companyName" placeholder="请输入公司名称" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="deliveryNotePage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeight()"
    >
      <el-table-column prop="companyName" label="公司" />
      <el-table-column prop="phone" label="电话" />
      <el-table-column prop="fax" label="传真" />
      <el-table-column prop="companyAddress" label="地址" />
      <el-table-column prop="url" label="网址" />
      <el-table-column prop="mailbox" label="邮箱" />
      <el-table-column prop="receivingUnit" label="收货单位" />
      <el-table-column prop="receivingAddress" label="收货地址" />
      <el-table-column label="送货日期">
        <template slot-scope="scope">
          <span v-if="scope.row.deliveryDate">{{ scope.row.deliveryDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="deliveryNotePage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import { addDeliveryNote, deleteDeliveryNote, updateDeliveryNote, selectDeliveryNoteInfo, selectDeliveryNoteList } from '@/api/sale/deliveryNote'
import { selectCustomerList } from '@/api/sale/customer'
import { selectProductList } from '@/api/sale/product'

export default {
  data () {
    return {
      deliveryNoteDialogVisible: false,
      deliveryNoteFormTitle: '',
      deliveryNoteForm: {
        id: '',
        customerId: '',
        phone: '',
        fax: '',
        companyAddress: '',
        url: '',
        mailbox: '',
        receivingUnit: '',
        receivingAddress: '',
        deliveryDate: '',
        deliveryNoteDetailJson: '',
        deliveryOperator: '',
        receivingOperator: ''
      },
      deliveryNoteFormRules: {
        customerId: [{ required: true, message: '公司不能为空', trigger: ['blur', 'change']}],
        deliveryDate: [{ required: true, message: '送货日期不能为空', trigger: ['blur', 'change']}]
      },
      deliveryNotePage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        companyName: ''
      },
      customerList: [],
      productList: [],
      deliveryNoteDetailList: []
    }
  },
  created () {
    selectCustomerList().then(res => {
      this.customerList = res.list
    })
    selectProductList().then(res => {
      this.productList = res.list
    })
    selectDeliveryNoteList(this.searchForm).then(res => {
      this.deliveryNotePage = res
    })
  },
  methods: {
    deliveryNoteDialogClose () {
      this.$refs.deliveryNoteFormRef.resetFields()
      this.deliveryNoteDetailList = []
    },
    deliveryNoteFormSubmit () {
      if (this.deliveryNoteFormTitle === '产品送货单详情') {
        this.deliveryNoteDialogVisible = false
        return
      }
      this.$refs.deliveryNoteFormRef.validate(async valid => {
        if (valid) {
          this.deliveryNoteForm.deliveryNoteDetailJson = JSON.stringify(this.$refs.xTable.getTableData().tableData)
          if (this.deliveryNoteFormTitle === '新增产品送货单') {
            await addDeliveryNote(this.deliveryNoteForm)
          } else if (this.deliveryNoteFormTitle === '修改产品送货单') {
            await updateDeliveryNote(this.deliveryNoteForm)
          }
          this.deliveryNotePage = await selectDeliveryNoteList(this.searchForm)
          this.deliveryNoteDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.deliveryNoteFormTitle = '新增产品送货单'
      this.deliveryNoteDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteDeliveryNote(row.id)
        if (this.deliveryNotePage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.deliveryNotePage = await selectDeliveryNoteList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.deliveryNoteFormTitle = '修改产品送货单'
      this.deliveryNoteDialogVisible = true
      this.selectDeliveryNoteInfoById(row.id)
    },
    handleInfo (index, row) {
      this.deliveryNoteFormTitle = '产品送货单详情'
      this.deliveryNoteDialogVisible = true
      this.selectDeliveryNoteInfoById(row.id)
    },
    selectDeliveryNoteInfoById (id) {
      selectDeliveryNoteInfo(id).then(res => {
        this.deliveryNoteForm.id = res.id
        this.deliveryNoteForm.customerId = res.customerId
        this.deliveryNoteForm.phone = res.phone
        this.deliveryNoteForm.fax = res.fax
        this.deliveryNoteForm.companyAddress = res.companyAddress
        this.deliveryNoteForm.url = res.url
        this.deliveryNoteForm.mailbox = res.mailbox
        this.deliveryNoteForm.receivingUnit = res.receivingUnit
        this.deliveryNoteForm.receivingAddress = res.receivingAddress
        this.deliveryNoteForm.deliveryDate = res.deliveryDate
        this.deliveryNoteForm.receivingOperator = res.receivingOperator
        this.deliveryNoteForm.deliveryOperator = res.deliveryOperator
        this.deliveryNoteDetailList = res.noteDetailList
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectDeliveryNoteList(this.searchForm).then(res => {
        this.deliveryNotePage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectDeliveryNoteList(this.searchForm).then(res => {
        this.deliveryNotePage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectDeliveryNoteList(this.searchForm).then(res => {
        this.deliveryNotePage = res
      })
    },
    customerChange (value) {
      if (value) {
        let customer = this.customerList.find(item => item.id === value)
        this.deliveryNoteForm.phone = customer.phone
        this.deliveryNoteForm.fax = customer.fax
        this.deliveryNoteForm.companyAddress = customer.companyAddress
        this.deliveryNoteForm.url = customer.url
        this.deliveryNoteForm.mailbox = customer.mailbox
        this.deliveryNoteForm.receivingUnit = customer.receivingUnit
        this.deliveryNoteForm.receivingAddress = customer.receivingAddress
      } else {
        this.deliveryNoteForm.phone = ''
        this.deliveryNoteForm.fax = ''
        this.deliveryNoteForm.companyAddress = ''
        this.deliveryNoteForm.url = ''
        this.deliveryNoteForm.mailbox = ''
        this.deliveryNoteForm.receivingUnit = ''
        this.deliveryNoteForm.receivingAddress = ''
      }
    },
    async insertRowEvent (row) {
      let { row: newRow } = await this.$refs.xTable.insertAt({}, -1)
      await this.$refs.xTable.setActiveRow(newRow)
    },
    removeRowEvent (row) {
      this.$refs.xTable.remove(row)
    },
    productChange ({ row }) {
      if (row.productId) {
        let product = this.productList.find(item => item.id === row.productId)
        row.spec = product.spec
        row.unit = product.unit
      } else {
        row.spec = ''
        row.unit = ''
      }
    }
  }
}
</script>

<style>
</style>
